
export default {
  name: "SupportSection",
  props: ['zone'],
  data() {
    return {
      logos: [
        {src: '/images/logos/wordpress_monotone_black.svg', alt: 'Logo of WordPress'},
        {src: '/images/logos/magento_monotone_black.svg', alt: 'Logo of Magento'},
        {src: '/images/logos/squarespace_monotone_black.svg', alt: 'Logo of Squarespace'},
        {src: '/images/logos/woocomerce_monotone_black.svg', alt: 'Logo of WooCommerce'},
        {src: '/images/logos/magento_monotone_black.svg', alt: 'Logo of Magento'},
        {src: '/images/logos/shopify_monotone_black.svg', alt: 'Logo of Shopify'},
      ],
      integrationLogos: [
        {src: '/images/logos/integrations/activecampaign.svg', alt: 'Logo of ActiveCampaign', width: 516, height: 53},
        {src: '/images/logos/integrations/mailchimp.svg', alt: 'Logo of Mailchimp', width: 185, height: 60},
        {src: '/images/logos/integrations/webhook.svg', alt: 'Logo of Webhook', width: 159, height: 44},
        {src: '/images/logos/integrations/zapier.svg', alt: 'Logo of Zapier', width: 80, height: 40},
      ],
    };
  },
  computed: {
    doubleIntegrationLogos() {
      return [...this.integrationLogos, ...this.integrationLogos];
    },
  },
};
